//
// Products
// --------------------------------------------------
.product-layout {
  @media only screen and (min-width: 321px) and (max-width: 767px) and (orientation: landscape) {
    &.col-xs-12 {
      width: 50%;
    }
  }
}
.product-thumb {
  border: 1px solid @pager-border;
  border-radius: 0;
  margin-bottom: @line-height-computed;
  background:transparent;
  overflow: hidden;
  background-color:@white;
  position: relative;
  .image {
    background: transparent;
    text-align: center;
    position: relative;
    overflow: hidden;

    a {
      display: block;
      &:hover {

      }
    }
    img {
      margin-left: auto;
      margin-right: auto;
      -webkit-transition: all 1s ease; /* Safari and Chrome */
      -moz-transition: all 1s ease; /* Firefox */
      -ms-transition: all 1s ease; /* IE 9 */
      -o-transition: all 1s ease; /* Opera */
      transition: all 1s ease;
    }

  }
  .image:hover img {
    -webkit-transform:scale(1.25); /* Safari and Chrome */
    -moz-transform:scale(1.25); /* Firefox */
    -ms-transform:scale(1.25); /* IE 9 */
    -o-transform:scale(1.25); /* Opera */
    transform:scale(1.25);
  }
  h4 {
    font-weight: normal;
    padding: 10px 10px 5px 10px;
    margin:0;
    font-family: 'Playfair Display', serif;
    font-style: italic;
    height:56px;
    a {
      color: @darkgray-dark;
      font-size: (@font-size-h4 - 2px);
    }
    a:hover{
      color: @bluejeans-dark;
    }
  }
  h3 {
    margin: 0;
  }
  .caption {
    padding: 0 @padding-large-vertical;

    text-align: center;
    height: 100%;
    p {
      display: none;
      &.price {
        color: radial-gradient(1% 1%, circle cover, @product-header-bg-invert,@product-header-bg);
        text-shadow: none;
        display: block;
        font-size:15px;
      }
    }
    .rating {
      display: none;
    }

  }

  .price {
    font-size: 12px;
    font-weight: normal;
  }
  .price-new {
    font-size: 15px;
    display: inline-block;
  }
  .price-old {
    color: @white;
    padding-left: @padding-large-horizontal;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0,0);
    text-decoration: line-through;
    background-color: @brand-primary;
    padding:5px 10px;
  }
  .price-tax {
    color: darknes(@product-button-group-text-color, 25%);
    display: block;
    font-size: @font-size-h6;
    font-weight: @headings-font-weight;
    padding: 0;
    display:none;
  }
  .button-group {
    position: relative;
    top: -54px;
    margin-bottom: -44px;
    background: transparent;
    overflow: auto;
    border: none;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: center;
    button {
      border: none;
      display: inline-block;
      background-color: rgba(0, 0, 0, 0.7);
      color: @product-fa-color;
      font-weight: bold;
      text-transform: uppercase;
      outline:0;
      padding: 15px;
      border-radius: 25px;
      width: 50px;
      height: 50px;
      background-color: rgba(10, 0, 0, 0.56);
      line-height: 20px;
      + button {
        margin-left: 10px;
      }
      &:hover {
        text-decoration: none;
        cursor: pointer;
        color: @product-button-group-text-color-hover;
        background: @brand-primary;
      }
    }
      .fa {
        font-size: 20px;
        color: @white;
      }

      span {
        display: none;
      }

      @media (max-width: 1200px) {

      }
      @media (max-width: 767px) {

      }

    }


  &:hover {
    border:1px solid @product-fa-color;
  }
}


.product-grid {
  .product-thumb .image {
    float: none;
  }
  .button-group {
    position: relative;
    top: 0px;
    margin-bottom: 0px;
  }
  @media (max-width: 1200px) {
    .product-thumb .caption {
      padding: 0 10px;
    }
  }
  @media (max-width: 767px) {
    .product-thumb .caption {
      min-height: 0;
    }
  }

}

.product-list {
  .button-group {
    position: relative;
    top: 0px;
    margin-bottom: 0px;
  }
  @media (min-width: 767px) {
    .product-thumb {
      text-align: center;
      position: relative;
      .image {
        background-color: white;
        height: inherit;
        float: left;
      }


      & .caption {
        overflow: hidden;
        text-align: center;

        p {
          display: block;
          &.price, .price-new, .price-old, .price-tax {
            text-align: center;
            display: inline-block;
            display: block;
            background: transparent;
            padding: @padding-base-vertical 0;
            font-weight: (@headings-font-weight + 100);
            position: inherit;
          }
        }
      }
      .button-group {
        width: inherit;
        border: none;

      }
      .rating {
        position: relative;
        background: transparent;
        display: block;
        text-align: center;
      }

    }
  }
  @media (max-width: 767px) {
    .product-thumb .caption {
      min-height: 0;
      margin-left: 0;
      padding: 0 @padding-large-vertical;
    }
  }
  @media (max-width: 768px) {
    .product-thumb .button-group {
      border-left: none;
    }
  }
  .product-thumb .rating {
    color:@product-rating-fa-color;
  }
}

.thumbnails {
  overflow: auto;
  clear: both;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -20px;

  & > li {
    margin-left: 20px;
    float: left;

  }
  & > img {
    width: 100%;
  }
  .image-additional {
    float: left;
    margin-left: 20px;
  }
}
.thumbnail {
  background: @body-default-bg-color;
  border-radius: 0px;
}
.image-additional {
  max-width: 78px;
  a {
    margin-bottom: @line-height-computed;
    padding: @padding-small-vertical;
    display: block;
    border: 1px solid @pager-border;
  }
}
.form-control {
  border-radius: 0px;
}

// Rating on product page
.rating {
  p {
    .fa{
      color: @product-rating-fa-color;
    }
  }
}

// Column left and right product box
#column-left, #column-right {
  .product-thumb {
    border:none;
    border-bottom: 1px solid #dddddd;
    .image {
      width: 35%;
      float: left;
    }
    // hidden button in left/right column
    .button-group {
      display: none;
    }
    &:hover {
      background: #f7f7f7;
    }
  }
}

