//
// Top
// --------------------------------------------------
.top {
  background-color: @top-background-color;
  padding-bottom:0px;
  padding-top: 0px;
  .currency {
    .btn {
      padding-top: 5px;
      font-size: (@font-size-base - 2px);
      line-height: 1;
    }
    .fa.fa-caret-down{
      font-size: (@font-size-base - 2px);
      margin-left: 2px;
      vertical-align: middle;
      color:@top-link-color;
    }
    .btn-link {
      color: @top-link-color;
      margin-top: 0;
    }
    .currency-select {
      text-align: left;
      &:hover {
        text-shadow: none;
        color: @navbar-inverse-link-color;
      }
    }

  }

  .btn-link {
    color: @top-link-color;
    text-decoration: none;
    font-size: (@font-size-base - 2px);
    &:hover {
      color: @navbar-inverse-link-color;
    }
    strong {
    font-size: floor((@font-size-base * 0.9));
    }
    .fa.fa-caret-down {
      font-size: 12px;
      margin-left: 2px;
      vertical-align: middle;
      color: #b3b0b0;
    }
  }
  li {
    .top .btn-link();

    a {
      .top .btn-link();
      font-size: (@font-size-base - 2px);
    }
  }
}

.top-links {
   padding-top: 5px;
  .list-inline {
    margin-bottom: 0px;
  }
  li {
    .top .btn-link();

    a {
      .top .btn-link();
      font-size: (@font-size-base - 2px);
    }
  }

  .dropdown-menu a {
    text-shadow: none;
    color: #b3b0b0;
    &:hover {
      background-color: transparent;
      color: @white;
    }
  }
}