//
// Menu
// ---------------------------------------------------
.menu {
  margin-bottom: 0px;
  z-index: 99;
  min-height: 40px;
    .nav {
    & > li {
      & > a {
        color: @navbar-default-link-color;
        background-color: transparent;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: normal;
        @media (max-width: 768px) {
        font-size: 14px;
          padding: 10px 8px;
        }
        &:hover {
          color: @brand-primary;
          background: rgba(0, 0, 0, 0);
          text-shadow: none;

        }
      }
    }

  }

  .navbar-nav {
    margin: 0;
    float: none;
    text-align: center;
    &>li {
      float: none;
      display: inline-block;
      &>a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
      @media (max-width: @screen-xs-max) {
        display: block;
      }
    }
  }
  .dropdown-menu {
    padding-bottom: 0;
    background: rgba(255, 255, 255, 0.90);
    border:1px solid rgb(255, 255, 255);
    @media (max-width: 767px) {
      margin-left: 0 !important;
      padding-bottom: @padding-large-vertical;
    }
  }
  .dropdown-inner {
    display: table;
     ul {
      display: table-cell;
     }
    a {
      min-width: floor((@screen-xs-max * 0.208604954));;
      display: block;
      padding: @padding-small-vertical @padding-small-horizontal;
      clear: both;
      line-height: @line-height-computed;
      color: @dropdown-link-color;
      font-size: @font-size-base;
      @media (max-width: 767px) {
        width: 100%;
        color: #000;
      }
    }
    li a:hover {
      background-color: #f1f1f1;
    }
    @media (max-width: @screen-xs-max) {
      display: block;
      & > .list-unstyled {
        display: block;
      }
    }
  }
  .see-all {
    display: block;
    padding: @padding-small-vertical @padding-small-horizontal;
    border-radius: 0 0 @border-radius-base @border-radius-base;
    font-size: @font-size-base;
    color: @red-dark;
    background-color: @white;
    &:hover {
      text-decoration: none;
      color: @white;
      background-color: @brand-primary;
    }
    @media (max-width: @screen-xs-max) {
      margin-top: 0;
      border: none;
      border-radius: 0;
      color:   @red-dark;
    }
  }
  .category {
    float: left;
    padding-left: @padding-large-horizontal;
    font-size: (@font-size-base + 4px);
    font-weight: @headings-font-weight;
    line-height: @navbar-height;
    color: @navbar-link-color;
    text-shadow: @close-text-shadow;
  }
  .btn-navbar {
    font-size: floor((@font-size-base * @line-height-base));

    color: lighten(@text-color,100%);
    padding: @padding-xs-vertical @padding-large-horizontal;
    float: right;
    background-color: @btn-navbar-bg;
    /*.background-image-linear-gradient(@brand-primary);*/
    /*border-color: @brand-primary darken(@brand-primary,10%) darken(@brand-primary,20%);*/
    &:hover {
      color: lighten(@text-color,100%);
      background-color: @brand-primary;
      .background-image-linear-gradient(lighten(@brand-primary,10%));

    }
  }
  .dropdown {
    &:hover .dropdown-menu {
      @media (min-width: (@screen-xs-max + 1px)) {
        display: block;
      }
    }
  }
  @media (max-width: @screen-xs-max) {
    border-radius: @border-radius-base;
  }
  .navbar-header {

    & > span {
      color: @navbar-default-link-color;;
      float: left;
      margin-top: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }

  }
}
