//
// Search input
// --------------------------------------------------
.search {
  input {
    padding: 0 @padding-base-horizontal;
    height: 25px;
    background-color: @top-background-color;
  }
  .btn {
    padding-left: (@padding-base-horizontal * 3);
    padding-right: (@padding-base-horizontal * 3);
    background-color: @top-background-color;
    color: @top-link-color;
  }
  .form-control ,.btn-default {
    border:none;
    border-bottom: 1px solid lighten(@top-background-color,20%);
    color:@white;
    border-radius: 0px;
  }
  .btn-default {
    color:@top-link-color;
    border-radius: 0px;
    height: 25px;
  }
  .btn-default :hover {
    color:@white;
  }
}